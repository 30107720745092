import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import { getProfileAvatar } from "@/game-csgo/utils.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import gameShortNameReassign from "@/util/game-short-name-reassign.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function AccountsCsgo() {
  const state = useSnapshot(readState);
  const {
    settings: {
      loggedInAccounts: { csgo },
    },
  } = state;

  const profiles = useMemo(() => {
    return Object.entries(csgo).reduce((profiles, [key, profile]) => {
      if (profile)
        profiles.push({
          displayName: profile.username,
          key,
          avatar: getProfileAvatar(),
        });
      return profiles;
    }, []);
  }, [csgo]);

  return (
    <Accounts
      profiles={profiles}
      gameShort={gameShortNameReassign(GAME_SHORT_NAMES[GAME_SYMBOL_CS2])}
      autoDetectText={[
        "common:settings.csgo.autoDetectProfile",
        "Hey! I can automatically detect your profile. Just open CSGO and log into the account you want to connect.",
      ]}
    />
  );
}
