import React from "react";

import AccountsCsgo from "@/hub-settings/AccountsCsgo.jsx";
import { PageContainer } from "@/hub-settings/Common.style.jsx";

export default function CSGOSettings() {
  return (
    <PageContainer>
      <AccountsCsgo />
    </PageContainer>
  );
}
